
import * as React from 'react';
import clsx from 'clsx';

import LowerPart from './LowerPart';

type Ref = HTMLDivElement;
type Props = React.ComponentPropsWithRef<'footer'>;

const Footer = React.forwardRef<Ref, Props>(({
  className,
  ...rest
}, ref): JSX.Element => (
  <footer
    ref={ref}
    className={clsx(
      'border-t',
      className
    )}
    aria-labelledby='footerHeading'
    {...rest}>
    <h2
      id='footerHeading'
      className='sr-only'>
      Footer
    </h2>
    <div
      className={clsx(
        'container',
        'mx-auto',
        'px-4',
        'sm:px-6',
        'lg:px-8',
        'py-4',
        'space-y-8',
        'divide-y',
        // ray test touch <<
        'divide-gray-200'
        // ray test touch >>
      )}>
      <LowerPart />
    </div>
  </footer>
));
Footer.displayName = 'Footer';

export default Footer;
