
// import * as React from 'react';
// import { useSubstrate } from 'substrate-lib';
// ray test touch <<
// import 'semantic-ui-css/semantic.min.css';
// ray test touch >>

import Layout from 'parts/Layout';
import Hero from 'components/Hero';
// import FullLoadingSpinner from 'components/FullLoadingSpinner';
// import AccountSelect from 'components/AccountSelect';

const App = (): JSX.Element => {
  // ray test touch <<
  // const [selectedAccountAddress, setSelectedAccountAddress] = React.useState<string>();
  // const {
  //   apiState,
  //   keyring,
  //   keyringState,
  //   apiError
  // } = useSubstrate();
  // const accountPair =
  //   selectedAccountAddress &&
  //   keyringState === 'READY' &&
  //   keyring.getPair(selectedAccountAddress);
  // if (apiState === 'ERROR') {
  //   return (
  //     // ray test touch <<
  //     // TODO: componentize
  //     <div>
  //       <p>Error Connecting to Substrate</p>
  //       <p>{JSON.stringify(apiError, null, 4)}</p>
  //     </div>
  //     // ray test touch >>
  //   );
  // } else if (apiState !== 'READY') {
  //   return (
  //     <FullLoadingSpinner text='Connecting to Substrate' />
  //   );
  // }
  // if (keyringState !== 'READY') {
  //   return (
  //     <FullLoadingSpinner text='Loading accounts (please review any extensions authorization)' />
  //   );
  // }
  // console.log('[App] accountPair => ', accountPair);
  // const handleSelectedAccountAddressChange = (newValue: string) => {
  //   setSelectedAccountAddress(newValue);
  // };
  // ray test touch >>

  return (
    <Layout>
      <div className='py-6'>
        {/* <AccountSelect
          selectedAccountAddress={selectedAccountAddress}
          selectAccountAddress={handleSelectedAccountAddressChange} /> */}
        <Hero />
      </div>
    </Layout>
  );
};

export default App;
