
import clsx from 'clsx';
import { GlobeAltIcon } from '@heroicons/react/outline';

import {
  INTERLAY_COMPANY_LINK,
  INTERLAY_TWITTER_LINK,
  INTERLAY_DISCORD_LINK,
  INTERLAY_GITHUB_LINK,
  INTERLAY_MEDIUM_LINK
} from 'config/links';
import { getCurrentYear } from 'utils/helpers/time';
import { ReactComponent as TwitterLogoIcon } from 'assets/img/icons/twitter-logo.svg';
import { ReactComponent as GitHubLogoIcon } from 'assets/img/icons/git-hub-logo.svg';
import { ReactComponent as DiscordLogoIcon } from 'assets/img/icons/discord-logo.svg';
import { ReactComponent as MediumLogoIcon } from 'assets/img/icons/medium-logo.svg';

const SOCIAL_ITEMS = [
  {
    name: 'Website',
    href: INTERLAY_COMPANY_LINK,
    // eslint-disable-next-line react/display-name
    icon: (props: React.ComponentPropsWithRef<'svg'>) => (
      <GlobeAltIcon
        width={24}
        height={24}
        {...props} />
    )
  },
  {
    name: 'Discord',
    href: INTERLAY_DISCORD_LINK,
    // eslint-disable-next-line react/display-name
    icon: (props: React.ComponentPropsWithRef<'svg'>) => (
      <DiscordLogoIcon
        width={18}
        height={12}
        {...props} />
    )
  },
  {
    name: 'Medium',
    href: INTERLAY_MEDIUM_LINK,
    // eslint-disable-next-line react/display-name
    icon: (props: React.ComponentPropsWithRef<'svg'>) => (
      <MediumLogoIcon
        width={16}
        height={14}
        {...props} />
    )
  },
  {
    name: 'Twitter',
    href: INTERLAY_TWITTER_LINK,
    // eslint-disable-next-line react/display-name
    icon: (props: React.ComponentPropsWithRef<'svg'>) => (
      <TwitterLogoIcon
        width={24}
        height={24}
        {...props} />
    )
  },
  {
    name: 'GitHub',
    href: INTERLAY_GITHUB_LINK,
    // eslint-disable-next-line react/display-name
    icon: (props: React.ComponentPropsWithRef<'svg'>) => (
      <GitHubLogoIcon
        width={24}
        height={24}
        {...props} />
    )
  }
];

const LowerPart = (): JSX.Element => (
  <div
    className={clsx(
      'py-4',
      'md:flex',
      'md:items-center',
      'md:justify-between'
    )}>
    <div
      className={clsx(
        'flex',
        'space-x-6',
        'md:order-2',
        'items-center'
      )}>
      {SOCIAL_ITEMS.map(item => (
        <a
          key={item.name}
          href={item.href}
          className={clsx(
            // ray test touch <<
            'text-gray-400',
            // ray test touch >>
            'hover:text-textSecondary'
          )}
          target='_blank'
          rel='noopener noreferrer'>
          <span className='sr-only'>{item.name}</span>
          <item.icon aria-hidden='true' />
        </a>
      ))}
    </div>
    <p
      className={clsx(
        'mt-8',
        'text-base',
        // ray test touch <<
        'text-gray-400',
        // ray test touch >>
        'md:mt-0',
        'md:order-1'
      )}>
      &copy; {getCurrentYear()} Kintsugi Labs. All rights reserved.
    </p>
  </div>
);

export default LowerPart;
