
import clsx from 'clsx';

import InterlayLink, { Props as InterlayLinkProps } from 'components/UI/InterlayLink';
import {
  KINTSUGI_TOKEN_ECONOMY_LINK,
  BRIDGE_APP_LINK
} from 'config/links';
import { ReactComponent as KintsugiLogoWithTextIcon } from 'assets/img/kintsugi-logo-with-text.svg';

const HeroLink = ({
  className,
  ...rest
}: InterlayLinkProps) => (
  <InterlayLink
    className={clsx(
      'w-full',
      'font-medium',
      'text-lg',
      'md:text-2xl',
      'block',
      'border',
      'border-interlayHaiti-50',
      'rounded-full',
      'px-6',
      'py-4',
      'inline-grid',
      'place-items-center',
      'hover:no-underline',
      'mx-auto',
      'hover:text-interlayDenim',
      className
    )}
    target='_blank'
    rel='noopener noreferrer'
    {...rest} />
);

const Hero = (): JSX.Element => (
  <div
    className={clsx(
      'max-w-5xl',
      'mx-auto',
      'md:pt-28' // Layout adjustment
    )}>
    <KintsugiLogoWithTextIcon
      className={clsx(
        'mx-auto',
        'p-10',
        'md:p-6',
        'w-full',
        'md:max-w-sm'
      )} />
    <div
      className={clsx(
        'space-y-10',
        'p-6'
      )}>
      <h1
        className={clsx(
          'text-center',
          'text-3xl',
          'md:text-5xl'
        )}>
        Bitcoin is coming to Kusama
      </h1>
      <div
        className={clsx(
          'space-y-4',
          'md:space-y-0',
          'md:grid',
          'md:grid-cols-2',
          'md:gap-8'
        )}>
        <HeroLink href={KINTSUGI_TOKEN_ECONOMY_LINK}>
          Crowdloan & Tokenomics
        </HeroLink>
        <HeroLink href={BRIDGE_APP_LINK}>
          Test Bridge
        </HeroLink>
      </div>
      <div
        className={clsx(
          'space-y-4',
          'md:space-y-0',
          'md:gap-8'
        )}>
        <h2
          className={clsx(
            'text-center',
            'text-2xl',
            'md:text-3xl'
          )}>
          We&apos;re sorry!
        </h2>
        <h2
          className={clsx(
            'text-center',
            'text-1xl',
            'md:text-1xl'
          )}>
          Kusama Crowdloans are not available in your country.
          Please refer to our&nbsp;
          <b>
            <u>
              <a href='https://drive.google.com/file/d/1DNhW3jKyvyuQ8oRtKgOB2FSGWbMQn4Mb'>
                Terms & Conditions
              </a>
            </u>
          </b>&nbsp;
          for more information.
        </h2>
      </div>
    </div>
  </div>
);

export default Hero;
