
const INTERLAY_COMPANY_LINK = 'https://www.interlay.io';
const INTERLAY_TWITTER_LINK = 'https://twitter.com/kintsugi_btc';
const INTERLAY_DISCORD_LINK = 'https://discord.gg/KgCYK3MKSf';
const INTERLAY_GITHUB_LINK = 'https://github.com/interlay';
const INTERLAY_MEDIUM_LINK = 'https://medium.com/interlay';
const BRIDGE_APP_LINK = 'https://bridge.interlay.io';
const KINTSUGI_TOKEN_ECONOMY_LINK =
  'https://raw.githubusercontent.com/interlay/whitepapers/master/Kintsugi_Token_Economy.pdf';

export {
  INTERLAY_COMPANY_LINK,
  INTERLAY_TWITTER_LINK,
  INTERLAY_DISCORD_LINK,
  INTERLAY_GITHUB_LINK,
  INTERLAY_MEDIUM_LINK,
  BRIDGE_APP_LINK,
  KINTSUGI_TOKEN_ECONOMY_LINK
};
